.btn {
    display: inline-block;
    padding: 0.5rem;
    border-radius: 10px;
    transition: transform 0.1s;
    cursor: pointer;
    text-align: center;
    user-select: none;

    /* disable blue select box on mobile */
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}


.enabled:active:hover {
    transform: scale(0.95);
}


@media (hover: hover) and (pointer: fine) {
    .btn:hover {
        transform:
                scale(1.1);
    }

}
