@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
.TickTackToe_gridBox__2ivaC:hover {
    z-index: 1;
}

.TickTackToe_cell__1_461 {
    position: relative;
    --xRot: 0deg;
    --yRot: 0deg;


    justify-content: stretch;
    aspect-ratio: 1;
    font-size: min(18vw, 5rem);
    box-shadow: 0 0 10px rgba(0,0,0, 0.6);
    transition: -webkit-transform 0.1s;
    transition: transform 0.1s;
    transition: transform 0.1s, -webkit-transform 0.1s;
    -webkit-transform:
            perspective(500px);
            transform:
            perspective(500px);
    cursor: pointer;

    /* disable blue select box on mobile */
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

    color: black;
}

.TickTackToe_cell__1_461:hover:active {
    -webkit-transform:
            perspective(500px)
            scale(0.95);
            transform:
            perspective(500px)
            scale(0.95);
}


@media (hover: hover) and (pointer: fine) {

    .TickTackToe_cell__1_461:hover:active {
        -webkit-transform:
                perspective(500px)
                rotateX(var(--xRot))
                rotateY(var(--yRot))
                scale(0.95);
                transform:
                perspective(500px)
                rotateX(var(--xRot))
                rotateY(var(--yRot))
                scale(0.95);
    }
    .TickTackToe_cell__1_461:hover {

        -webkit-transform:
                perspective(500px)
                rotateX(var(--xRot))
                rotateY(var(--yRot))
                scale(1.1);

                transform:
                perspective(500px)
                rotateX(var(--xRot))
                rotateY(var(--yRot))
                scale(1.1);
    }
}

.TickTackToe_cell__1_461 > div {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: grid;
    place-content: center;
    transition: opacity 0.5s;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}



.TickTackToe_cellShowEmpty__19sje * .TickTackToe_cellEmpty__1LCWc, .TickTackToe_cellShowPlayer__2QNA8 * .TickTackToe_cellUserClicked__2Ftdj, .TickTackToe_cellShowPc__3_E2N * .TickTackToe_cellPcClicked___TbDH {
    opacity: 1;
}


.TickTackToe_cellEmpty__1LCWc {
    background: gray;

}

.TickTackToe_cellUserClicked__2Ftdj {
    background: linear-gradient(45deg, yellow, green);
}

.TickTackToe_cellPcClicked___TbDH {
    background: linear-gradient(45deg, red, darkred);
}


.TickTackToe_game__1KGx0 {
    place-content: center;
    display: grid;
    grid-gap: min(4px, 1.5vw);
    grid-template-columns: repeat(3, minmax(20%, 128px));
    margin: 1rem
}


@media only screen and (min-width: 500px) {
    .TickTackToe_game__1KGx0 {
        grid-template-columns: repeat(3, 128px);
    }
}

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    line-height: 1;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

body {
    font-family: 'Roboto', sans-serif;

    --bgCol: #19323C;
    --textCol: #D7D9CE;

    background-color: var(--bgCol);
    color: var(--textCol);
}


h1 {
    margin-top: 1rem;
    font-size: 200%;
}

.Button_btn__3xaJM {
    display: inline-block;
    padding: 0.5rem;
    border-radius: 10px;
    transition: -webkit-transform 0.1s;
    transition: transform 0.1s;
    transition: transform 0.1s, -webkit-transform 0.1s;
    cursor: pointer;
    text-align: center;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;

    /* disable blue select box on mobile */
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}


.Button_enabled__3L6MM:active:hover {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
}


@media (hover: hover) and (pointer: fine) {
    .Button_btn__3xaJM:hover {
        -webkit-transform:
                scale(1.1);
                transform:
                scale(1.1);
    }

}

.App_btn__34jjX {
    color: white;
    font-size: min(1rem, 6vw);
    min-width: 5em;
}


.App_btnEnabled__1mzxO {
    background: darkblue;
}

.App_btnDisabled__3aJ9d {
    background: lightslategray;
}

.App_headerText__1Yih3 {
    text-align: center;
    font-size: min(2rem, 8vw);
}


/* for sticky footer */
.App_container__1MQN3 {
    min-height: 100vh;
    display: grid;
    grid-template-rows: 1fr auto;
}

.App_footer__29Fsv {
    grid-row-start: 2;
    grid-row-end: 3;
}
.App_footer__29Fsv > p {
    text-align: right;
    padding-bottom: 5px;
    padding-right: 5px;
}

