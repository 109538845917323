.gridBox:hover {
    z-index: 1;
}

.cell {
    position: relative;
    --xRot: 0deg;
    --yRot: 0deg;


    justify-content: stretch;
    aspect-ratio: 1;
    font-size: min(18vw, 5rem);
    box-shadow: 0 0 10px rgba(0,0,0, 0.6);
    transition: transform 0.1s;
    transform:
            perspective(500px);
    cursor: pointer;

    /* disable blue select box on mobile */
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

    color: black;
}

.cell:hover:active {
    transform:
            perspective(500px)
            scale(0.95);
}


@media (hover: hover) and (pointer: fine) {

    .cell:hover:active {
        transform:
                perspective(500px)
                rotateX(var(--xRot))
                rotateY(var(--yRot))
                scale(0.95);
    }
    .cell:hover {

        transform:
                perspective(500px)
                rotateX(var(--xRot))
                rotateY(var(--yRot))
                scale(1.1);
    }
}

.cell > div {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: grid;
    place-content: center;
    transition: opacity 0.5s;
    user-select: none;
}



.cellShowEmpty * .cellEmpty, .cellShowPlayer * .cellUserClicked, .cellShowPc * .cellPcClicked {
    opacity: 1;
}


.cellEmpty {
    background: gray;

}

.cellUserClicked {
    background: linear-gradient(45deg, yellow, green);
}

.cellPcClicked {
    background: linear-gradient(45deg, red, darkred);
}


.game {
    place-content: center;
    display: grid;
    grid-gap: min(4px, 1.5vw);
    grid-template-columns: repeat(3, minmax(20%, 128px));
    margin: 1rem
}


@media only screen and (min-width: 500px) {
    .game {
        grid-template-columns: repeat(3, 128px);
    }
}
